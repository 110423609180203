import {
    required
    // url
} from 'vuelidate/lib/validators';

export const validationRules = {
    single: {
        url: {
            required
            // url
        }
    },
    absent: {
        url: {
            required
            // url
            // urlValidation(value) {
            //     if (!this.$v.url.required || !this.$v.url.url || !value) return true;
            //     return new Promise((resolve) => {
            //         this.validateUrl(value)
            //             .then(() => {
            //                 resolve(true)
            //             })
            //             .catch((err) => {
            //                 this.$set(this.serverErrors, 'url', err?.response?.data?.errors?.url)
            //                 console.log(this.serverErrors)
            //                 resolve(true)
            //             })
            //     })
            // }
        }
    },
    bulk: {
        url_in_bulk: {
            required,
            urlBulkValidation(value) {
                return value
                // if (!this.$v.url_in_bulk.required || !value) return true;
                // return new Promise((resolve) => {
                //     const arrayUrl = value.split('\n')
                //     resolve(arrayUrl.reduce((acc, link) => acc && url(link), true))
                // })
            }
        }
    }
}
