import editorBaseMixin from '@/mixins/editor/editorBaseMixin';
import { editorItems } from '@/helpers/constants/edit-pbn-page/editor-items'

// Validation
import formValidationMixin from '@/mixins/formValidationMixin.js'
import { validationMixin } from 'vuelidate';
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/CreatePbn/Rules'
import { formMessages } from '@/validation/CreatePbn/Messages'

import { mapState, mapMutations } from 'vuex'

import { mapSeoFields } from '@/store/modules/seo/seo-edit/index.js'

import {
    RESET_CREATED_FORM_DATA
} from '@/store/modules/permissions/permissions-edit/mutation-types'

import { isEqual } from 'lodash'

export default {
    mixins: [
        editorBaseMixin,
        validationMixin,
        formValidationMixin
    ],
    validations() {
        return validationRules.bulk
    },
    watch: {
        form_data: {
            deep: true,
            handler() {
                if (!this.checkIsEqual) {
                    this.unfinished_session = true
                    this.unfinished_url = this.$route.path
                    return
                }
                this.unfinished_session = false
                this.unfinished_url = null
            }
        }
    },
    computed: {
        editorItems() {
            return editorItems.bulk
        },
        ...mapState('auth', ['user']),
        ...mapSeoFields([
            'pbn',
            'pbn.form_data',
            'pbn.form_data.url',
            'pbn.form_data.price',
            'pbn.form_data.type_payment',
            'pbn.form_data.info_payment',
            'pbn.form_data.url_in_bulk',
            'loading'
        ]),
        checkIsEqual() {
            return isEqual(this.form_data, this.default_form_data)
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapMutations('seoEdit', [RESET_CREATED_FORM_DATA]),
        back() {
            this.$router.back()
        }
    }
}
