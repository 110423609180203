<template>
    <editor-layout :loading="loading">
        <template #header>
            <editor-header
                :title="'New Links'"
            />
        </template>
        <template #content>
            <form @submit.prevent="openSaveModal">
                <component
                    :is="'editor-' + editorItem.component"
                    v-for="(editorItem, id) in editorItems"
                    :key="id"
                    v-model="$v[editorItem.name].$model"
                    :class="`${editorItem.name}-editor-field`"
                    :disabled="loading"
                    :legend="getLinksCount"
                    :config="user"
                    :label="editorItem.name"
                    :field.sync="$v[editorItem.name].$model"
                    :errors="(serverErrors[editorItem.name] && serverErrors[editorItem.name][0]) || validationMessage($v[editorItem.name])"
                    :is-valid="$v[editorItem.name].$dirty && !$v[editorItem.name].$anyError"
                    @input.native="inputHandeler(editorItem)"
                    @blur.native="$v[editorItem.name].$touch()"
                />
                <div class="editor-form__buttons">
                    <custom-button
                        :disabled="loading"
                        default
                        type="submit"
                    >
                        CREATE PBN
                    </custom-button>
                    <custom-button
                        :disabled="loading"
                        default
                        type="button"
                        @on-btn-click="openResetModal"
                    >
                        RESET FORM
                    </custom-button>
                </div>
            </form>
        </template>
        <template #modals>
            <confirm-modal
                :title="'Reset Pbn?'"
                :content="'Are you sure?'"
                :name="'reset-modal'"
                @onConfirmClick="reset"
            />
            <confirm-modal
                :title="'Save Pbn?'"
                :content="'Are you sure?'"
                :name="'save-modal'"
                @onConfirmClick="checkRouteForValidate"
            />
        </template>
    </editor-layout>
</template>

<script>
import pbnMixin from '@/mixins/editor/pbn-page'
import { urlValidator } from '@/services/seoLinksService'

import {
    ACTION_CREATE_PBN_POST
} from '@/store/modules/seo/seo-edit/action-types'
import { createNamespacedHelpers } from 'vuex';
import { errorHandler } from '@/services/errorHandelerServeice'

const {
    mapActions: mapSeoEditActions
} = createNamespacedHelpers('seoEdit');

export default {
    name: 'CreatePbnPage',
    mixins: [
        pbnMixin
    ],
    data() {
        return {
            serverErrors: {},
            timer: null
        }
    },
    computed: {
        getLinksCount() {
            return `${this.url_in_bulk.split('\n').filter((item) => !!item).length} links`
        }
    },
    created() {

    },
    methods: {
        ...mapSeoEditActions([
            ACTION_CREATE_PBN_POST
        ]),
        submit() {
            this[ACTION_CREATE_PBN_POST]({ data: { ...this.form_data } })
                .then(() => {
                    this.$bus.$emit('showSnackBar', 'Your pbn link was created', 'success')
                    this.$v.$reset()
                    this.$router.push({ name: 'posts-list-pbn' })
                })
                .catch((err) => {
                    const { inputError, errorSnackBarText } = errorHandler.getBulkError(err?.response?.data || {})
                    this.$set(this.serverErrors, 'url_in_bulk', [inputError])
                    this.$bus.$emit('showSnackBar', errorSnackBarText, 'error')
                })
        },
        checkRouteForValidate() {
            if (this.$route.name === 'create-pbn') {
                this.validate()
            }
        },
        reset() {
            if (this.$route.name === 'create-pbn') {
                this.RESET_CREATED_FORM_DATA({ name: 'pbn' })
                this.$v.$reset()
            }
        },
        inputHandeler(editorItem) {
            this.serverErrors[editorItem.name] = []
        },
        async validateUrl(url) {
            return new Promise((resolve, reject) => {
                clearTimeout(this.timer)
                this.timer = setTimeout(async () => {
                    try {
                        await urlValidator(url)
                        resolve()
                    } catch (e) {
                        reject(e)
                    }
                }, 500)
            })
        },
        async validateBlinkUrls(urls) {
            return new Promise((resolve, reject) => {
                clearTimeout(this.timer)
                this.timer = setTimeout(async () => {
                    try {
                        const requests = urls.map(urlValidator)
                        await Promise.all(requests).then(() => {
                            resolve()
                        }, (e) => {
                            throw e
                        });
                        resolve()
                    } catch (e) {
                        reject(e)
                    }
                }, 2000)
            })
        }
    }
}
</script>

<style scoped lang="scss">
    .topBaner {
        background: #3872ca;
        color: #fff;
        padding: 15px;
        border-radius: 3px;
        margin-bottom: 30px;
    }
    .checkbox_wrap {
        margin: 20px auto;
    }
</style>

<style lang="scss">
    .url_in_bulk-editor-field textarea{
        height: 500px;
        font-size: 16px;
        line-height: 35px;
    }
</style>
